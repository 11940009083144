<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-29 15:08:00
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-11-29 15:08:12
-->
<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style>
</style>